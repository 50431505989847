@import "elements/base";

@import "partials/points";

/* No Files Alert for Point Modal Photos, Videos, Audio tabs */
.pointModal .alert {
  text-align: center;
  margin-bottom: 30px;
}
/* Film icon for Point Modal Videos tab */
.jumbotron-icon .icon-2{font-size:2em;}
.jumbotron-icon .icon-3{font-size:4em;}
.jumbotron-icon .icon-4{font-size:7em;}
.jumbotron-icon .icon-5{font-size:12em;}
.jumbotron-icon .icon-6{font-size:20em;}

.jumbotron-icon {
  overflow: hidden;
}
.pointModal .jumbotron-icon .icon-film {
  width: auto;
  height: auto;
  line-height: normal;
  display: block;
  margin-left: 19px;
  margin-right: 19px;
}
/* Point and Tour Tables sort icon left margin */
.icon-sort-down,
.icon-sort-up {
  margin-left: 8px;
}
/* Text color */
.accent-color-1 {
  color: #a1a1a1;
}
/* Left Side Nav panel styles */
.header-style-1 {
  font-size: 1.2em;
  line-height: 1.2em;
  text-shadow: none;
}
/* Project List Partial Gear Menu */
.dropdown .dropdown-menu {
  text-transform: none;
  text-align: center;
  font-size: 14px;
}
/* Menu caret top right corner */
.gear-menu  .dropdown-menu::before {
  position: absolute;
  top: -7px;
  right: 12px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #CCC;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.gear-menu  .dropdown-menu::after {
  position: absolute;
  top: -6px;
  right: 13px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  border-left: 6px solid transparent;
  content: '';
}

.text-style-1 {
  font-size: 0.9em;
  font-weight: normal;
  text-align: right;
  padding-top: 10px
}
/* Search Bar */
.search-bar {
  position: relative;
  margin-top: 0;
}
/* Placeholder Padding Fix */
.table-controls .navbar-search .search-query:-moz-placeholder {
    padding-top: 1px;
}
.table-controls .navbar-search .search-query::-ms-input-placeholder {
  padding-top: 1px;
}
.table-controls .navbar-search .search-query::-webkit-input-placeholder {
  padding-top: 1px;
}

#ibeaconContent .search-bar .icon-search,
#tourContent .search-bar .icon-search {
  position: absolute;
  left: 10px;
  top: 7px;
  cursor:pointer;
}

/* Search Input Text Left Padding */
.pointModal .light-table-filter,
.navbar-search .light-table-filter {
  padding-left: 30px;
}
/* Point and Change Password Modal Button bottom padding fix */
#pointCategoriesTab .btn,
#changePasswordForm .btn {
  margin-bottom: 10px;
}
/* Point and Tours table button bottom padding fix */
.table-controls {
  padding-bottom: 10px;
}
/* Primary Button Focus Fix */
.btn-primary:focus {
  background-color: #A0C134;
  background-position: 0;
}
/* Map Point style */
.point-map h5 {
  color: #A0C134;
}
/* Point Details Right Side Header */
.well.span3 h4 {
  text-align: center;
  font-weight: normal;
  font-size: 1.7em;
  line-height: 1.3em;
}
/* Point Details Right Side SubHeaders */
.well.span3 h5 {
  color: #A0C134;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 18px;
}
/* Point Details Right Side Background */
.well.span3 {
  background-color: #F8F8F8;
}
/* Point and Tours Table Border and Border Radius */
.outer-border {
  border: 1px solid #ededed;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  border-collapse: inherit;
}
/* Point and Tours table row highlight */
.table-striped tbody tr.highlight td {
  color: white;
  background-color: #a0c134;
}
/* viaPlace Top Left Small Logo */
.navbar .head-logo {
  width: 65px;
  height: 30px;
  background: url('../img/HeaderLogo-sml.png') no-repeat;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin: 5px;
  margin-bottom: 0;
  padding: 5px;
  padding-bottom: 0;
}
/* viaPlace Large Logo */
.via-logo {
  width: 278px;
  height: 130px;
  background: url('../img/viaPlaceLogo.png') no-repeat;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 100px;
  margin-bottom: 20px;
  text-align: center;
}
/* Login Styles */
.login-container {
  width: 300px;
}
/* The white background content wrapper */
.login-container .content {
  background-color: #fff;
  padding: 30px;
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.15);
  -moz-box-shadow: 0 1px 2px rgba(0,0,0,.15);
  box-shadow: 0 1px 2px rgba(0,0,0,.15);
}
/* Sign in Button styles */
.login-form .btn {
  display: block;
}
/* Sign in Form styles */
.login-form form {
  margin-bottom: 5px;
}


/* Modal styles */
.pointModal{
  width:700px;
  margin-left:-350px;
}

.tourModal{
  width:600px;
  margin-left:-300px;
}

.modalTabContents {
  overflow:auto;
}

.uplodaderContents {
  max-height:200px;
  overflow:auto;
}

.thumbnails {
  max-height:250px;
  overflow:auto;
}

.vpthumb {
  max-width: 100px;
  max-height: 100px;
}

.menu .btn{
  float:none;
}

/* Applications section container */
.appWell {
  border-radius: 25px;
  padding: 10px;
  margin: 10px 0;
}

.google-map img {
  max-width: none;
}

#modal-map-canvas {
  margin: 0;
  padding: 0;
  height: 200px;
  width:620px;
  border: 1px solid #ccc;
}

#modal-map-canvas img {
  max-width: none;
}

.faux-link:hover{
  cursor:pointer;
}

#main-portal-view {
  margin-top: 50px;
}

.vp-points-selector {
  width: 100%;
}

/* This is needed to prevent flicker during load */
[ng\:cloak],
[ng-cloak],
.ng-cloak {
  display: none !important;
}

.login-container {
  margin-top: 50px;
}

#tourPointSelect {
  option {
    &.inactive {
      color: lightgrey;
    }
  }
}

div[data-vp-projects-list] {
  margin: 0 !important;
}

.modal-backdrop,
.modal-backdrop.fade.in {
  background-color: #000;
}

.form-datetime {
  input.datetime,
  .add-on {
    cursor: pointer;
  }
}

#beaconActive {
  float: left;
  clear: none;
  margin-right: 10px;

  & + label {
    font-weight: bold;
    line-height: 20px;
    float: left;
    clear: none;
  }
}

// Fix for the fact that we are using both Font Awesome and Bootstrap Icons
a {
  [class^="icon-"],
  [class*=" icon-"] {
    display: inline-block;
  }
}