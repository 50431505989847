legend {
  margin-right: -50px;
  font-weight: bold;
  color: #404040;
}

table {
  width: 100%;
  cursor: default;
}

th.center {
  text-align: center;
}

th.id, th.isActive {
  width: 5%;
}

th.name {
  width: 16%;
}

th.buttons, th.tourButtons {
  width: 12%;
}

th.address {
  width: 30%;
}

th.categories, th.tags {
  width: 12%;
}

th.tourCheckbox {
  width: 10%;
}

th.tourNumPoints {
  width: 15%;
}

td.center {
  text-align: center;
}

td.icon {
  width: 5%;
}

.disabled {
  color: lightgrey;
}

*[disabled] {
  cursor: not-allowed;
  color: lightgrey;
}

a {
  &:not([href]) {
    cursor: pointer;
  }
}
.top-buffer {
  margin-top:20px;
}